import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Container from '../../UI/Container/Container'

const History = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <div className="pt-14 md:pt-32">
      <Container>
        <div className="md:pl-20">
          <div className="text-xl text-white font-bold">{t.about.history.headline}</div>
          <div className="flex flex-col md:flex-row gap-10 mt-3">
            <div className="md:w-3/12 font-bold text-sm text-white">{t.about.history.subline}</div>
            <div className="md:w-9/12 flex flex-col gap-7">
              {t.about.history.text.map((text, i) => (
                <div className="font-thin text-white" key={i}>
                  {text}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default History
