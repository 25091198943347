import bg from '../../../assets/image/home/management/bg.webp'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Container from '../../UI/Container/Container'
import flight from '../../../assets/image/home/management/flight.svg'
import landing from '../../../assets/image/home/management/landing.svg'
import start from '../../../assets/image/home/management/start.svg'

const Management = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const icons = [flight, landing, start]

  return (
    <div className="bg-blue">
      <div className="bg-repeat bg-cover py-20 pb-40 md:py-40 bg-center" style={{ backgroundImage: `url(${bg})` }}>
        <Container>
          <div className="md:pl-20">
            <div className="text-white text-xl font-bold md:w-2/3">{t.home.management.title}</div>
            <div className="text-white font-thin md:w-1/2 mt-7">{t.home.management.text}</div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="flex flex-col md:flex-row gap-7">
          {t.home.management.sections.map((section, i) => (
            <div className="p-10 flex-1 md:p-16 bg-gold flex flex-col items-center shadow-md -translate-y-20" key={i}>
              <img src={icons[i]} alt="" className="w-20 h-20 object-contain object-center" />
              <div className="uppercase text-lg font-thin text-center text-white mt-5">{section.title}</div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Management
