import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import './Global.Styled'
import Home from './components/Home/Home'
import Service from './components/Service/Service'
import Contact from './components/Contact/Contact'
import Imprint from './components/Legal/Imprint'
import DataProtection from './components/Legal/DataProtection'
import About from './components/About/About'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            {/* Default */}
            <Route index element={<Home />} />
            <Route path="service" element={<Service />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />

            {/* Legal */}
            <Route path="impressum" element={<Imprint />} />
            <Route path="datenschutz" element={<DataProtection />} />

            {/* fallback routing */}
            <Route path={'*'} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  )
}

export default App
