import React from 'react'

type Props = {
  onSubmit: () => void
  children: React.ReactNode
  className?: string
}

const Form: React.FC<Props> = ({ onSubmit, children, className }) => {
  const submit = (e) => {
    e.preventDefault()
    onSubmit()
  }

  return (
    <form onSubmit={submit} className={className}>
      {children}
    </form>
  )
}

export default Form
