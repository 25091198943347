import image from '../../../assets/image/home/specials/image.webp'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Container from '../../UI/Container/Container'

const Specials = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="bg-blue md:pt-10">
      <Container>
        <div className="flex flex-col md:items-center md:flex-row gap-10">
          <div className="flex-1">
            <div className="text-xl font-bold text-white">{t.home.specials.headline}</div>
            <div className="font-bold text-white">{t.home.specials.subline}</div>
            <div className="text-white mt-7">{t.home.specials.text}</div>
          </div>
          <div className="flex-1 flex flex-col md:items-center">
            <div className="text-lg font-thin uppercase text-white">{t.home.specials.bullets.title}</div>
            <div className="flex flex-col gap-3 mt-7">
              {t.home.specials.bullets.items.map((item, i) => (
                <div key={i} className="flex gap-2">
                  <div className="h-5 aspect-square shrink-0 bg-goldDark rounded-full"></div>
                  <div className="font-bold text-white">{item}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex-1 bg-cover bg-center aspect-4/5" style={{ backgroundImage: `url(${image})` }}></div>
        </div>
      </Container>
    </div>
  )
}

export default Specials
