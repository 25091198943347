import Hero from '../UI/Hero/Hero'
import Layout from '../UI/Layout/Layout'
import Sections from './Sections/Sections'
import bg from '../../assets/image/service/hero/bg.webp'
import text from '../../assets/image/service/hero/text.svg'

const Service = () => {
  return (
    <Layout>
      <Hero bg={bg}>
        <img src={text} alt="1stClassJets" className="h-16" />
      </Hero>
      <Sections />
    </Layout>
  )
}

export default Service
