import { useSelector } from 'react-redux'
import Container from '../../Container/Container'
import facebook from '../../../../assets/image/socialmedia/facebook.webp'
import instagram from '../../../../assets/image/socialmedia/instagram.webp'
import linkedin from '../../../../assets/image/socialmedia/linkedin.webp'
import logoFull from '../../../../assets/image/logo/logo_full.svg'
import props from '../../../../redux/props'
import Shadow from '../../Shadow/Shadow'

const Footer = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const icons = {
    facebook: facebook,
    instagram: instagram,
    linkedin: linkedin,
  }

  return (
    <div className="bg-blue relative">
      <Shadow />
      <Container>
        <div className="py-28 pb-16 flex flex-col gap-10">
          <div className="flex flex-col md:flex-row items-start justify-between gap-10 md:gap-20">
            <div className="w-full md:w-4/12 flex justify-center">
              <img src={logoFull} alt="1stClassJets" className="w-[80%] md:w-full" />
            </div>
            <div className="md:w-3/12 flex flex-col gap-3 md:gap-5">
              <div className="text-white font-bold uppercase">{t.footer.contact}</div>
              <a href={`tel:${t.generic.company.phone}`} className="text-white font-thin">
                {t.generic.company.phone}
              </a>
              <a href={`mailto:${t.generic.company.mail}`} className="text-white font-thin">
                {t.generic.company.mail}
              </a>
            </div>
            <div className="md:w-3/12 flex flex-col gap-3 md:gap-5">
              <div className="text-white font-bold uppercase">{t.footer.links}</div>
              {t.footer.legalLinks.map((link: { name: string; link: string }, i: number) => (
                <a href={link.link} className="font-thin text-white" key={i}>
                  {link.name}
                </a>
              ))}
            </div>
            <div className="md:w-2/12 flex flex-col gap-3 md:gap-5">
              <div className="text-white font-bold uppercase">{t.footer.followUs}</div>
              <div className="flex gap-7">
                {t.footer.socialLinks.map((link: { icon: string; link: string }, i: number) => (
                  <a href={link.link} className="font-thin text-white" key={i} target="_blank" rel="noreferrer">
                    <img src={icons[link.icon]} className="h-5" alt={link.icon} />
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="font-thin text-white text-center md:text-right">
            &copy;{` ${new Date().getFullYear()} `}
            {t.generic.company.name}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer
