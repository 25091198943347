import Layout from '../UI/Layout/Layout'
import Hero from './Hero/Hero'
import Intro from './Intro/Intro'
import Management from './Management/Management'
import Specials from './Specials/Specials'
import Numbers from './Numbers/Numbers'
import Ansprueche from './Ansprueche/Ansprueche'
import ContactCTASection from './ContactCTASection/ContactCTASection'

const Home = () => {
  return (
    <Layout>
      <Hero />
      <Intro />
      <Management />
      <Specials />
      {/* <Numbers /> */}
      <Ansprueche />
      <ContactCTASection />
    </Layout>
  )
}

export default Home
