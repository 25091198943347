import Footer from './Footer/Footer'
import ScrollToTop from './ScrollToTop'

type Props = {
  children: React.ReactNode
  className?: string
  noFooter?: boolean
}

const Layout: React.FC<Props> = ({ children, className, noFooter = false }) => {
  return (
    <div className={`bg-background ${className}`}>
      <ScrollToTop />
      <div className={`relative overflow-x-hidden min-h-screen`}>{children}</div>
      {!noFooter && <Footer />}
    </div>
  )
}

export default Layout
