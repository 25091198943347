import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'
import ContactForm from './ContactForm'
import props from '../../../redux/props'
import Shadow from '../../UI/Shadow/Shadow'

const FormWrapper = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="bg-blue py-20 md:py-40 relative">
      <Shadow />
      <Container>
        <div className="flex flex-col md:flex-row gap-20">
          <div className="flex flex-col gap-5 md:w-1/2">
            <div className="text-xl text-white font-bold">{t.contact.headline}</div>
            <div className="text-white font-thin">{t.contact.text}</div>
            <div className="font-bold text-white">{t.generic.company.brandName}</div>
            <div>
              <div className="text-white font-thin">{t.generic.company.name}</div>
              <div className="text-white font-thin">{t.generic.company.street}</div>
              <div className="text-white font-thin">{t.generic.company.city}</div>
            </div>
            <div>
              <div className="text-white font-bold">{t.generic.phone}</div>
              <a href={`tel:${t.generic.company.phone}`} className="text-white font-thin">
                {t.generic.company.phone}
              </a>
            </div>
            <div>
              <div className="text-white font-bold">{t.generic.mail}</div>
              <a href={`mailto:${t.generic.company.mail}`} className="text-white font-thin">
                {t.generic.company.mail}
              </a>
            </div>
          </div>
          <div className="md:w-1/2">
            <ContactForm />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default FormWrapper
