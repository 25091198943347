import { useState } from 'react'
import Form from '../../UI/Form/Form'
import Input from '../../UI/Input/Input'
import Textarea from '../../UI/Textarea/Textarea'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Button from '../../UI/Button/Button'
import submitToLambda from './submitToLambda'

type ContactRequest = {
  name: string
  mail: string
  message: string
}

enum Status {
  SUCCESS,
  ERROR,
}

const ContactForm = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [status, setStatus] = useState<Status>()
  const [data, setData] = useState<ContactRequest>({} as ContactRequest)

  const set = (key: keyof ContactRequest) => (value: string) => setData({ ...data, [key]: value })

  const submit = async () => {
    const response = await submitToLambda(data)
    if (!!response && !!response.status) {
      setStatus(Status.SUCCESS)
      setData({} as ContactRequest)
    } else {
      setStatus(Status.ERROR)
    }
  }

  return (
    <div className="md:shadow-md shadow-shadowBlue md:p-10">
      <Form onSubmit={submit}>
        <div className="font-bold text-white">{t.contact.form.title}</div>
        <div className="flex flex-col gap-2 mt-3 md:w-[90%]">
          <Input invert required placeholder={t.generic.name} value={data.name} onChange={set('name')} />
          <Input invert type="email" required placeholder={t.generic.mail} value={data.mail} onChange={set('mail')} />
          <Textarea invert required placeholder={t.generic.message} value={data.message} onChange={set('message')} />
        </div>
        <div className="flex justify-end gap-1">
          <Button text={t.contact.cta} type="submit" contrast small className="mt-10" />
        </div>
        {status === Status.SUCCESS && <div className="mt-2 text-white">{t.contact.form.success}</div>}
        {status === Status.ERROR && <div className="mt-2 text-white">{t.contact.form.error}</div>}
      </Form>
    </div>
  )
}

export default ContactForm
