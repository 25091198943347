import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Container from '../../UI/Container/Container'
import time from '../../../assets/image/home/ansprueche/time.svg'
import luggage from '../../../assets/image/home/ansprueche/luggage.svg'
import circle from '../../../assets/image/home/ansprueche/circle.svg'

const Ansprueche = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const icons = [time, luggage, circle]

  return (
    <div className="bg-blue py-32">
      <Container>
        <div className="font-bold text-xl text-white">{t.home.ansprueche.title}</div>
        <div className="flex flex-col md:flex-row gap-10 mt-20">
          {t.home.ansprueche.items.map((item, i) => (
            <div key={i} className="flex-1 flex justify-center gap-5">
              <img src={icons[i]} alt={item} className="w-14 h-14 object-contain" />
              <div>
                <div className="text-white font-thin uppercase text-lg">{item.title}</div>
                <div className="text-white font-thin mt-1">{item.text}</div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Ansprueche
