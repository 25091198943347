export const translation = {
  projectName: '1stClassJets - Aircraft Management',

  header: {
    navigation: {
      default: [
        {
          name: 'Home',
          link: '/',
        },
        {
          name: 'About',
          link: '/about',
        },
        {
          name: 'Services',
          link: '/service',
        },
      ],
      ADMIN: [
        {
          name: 'Home',
          link: '/admin/customer',
        },
      ],
      isLoggedIn: {
        name: 'Abmelden',
      },
    },
  },

  home: {
    intro: {
      welcome: 'Welcome to',
      text: ['Zeitgemäßes Aircraft Management auf höchstem Niveau.', 'Services, die Diskretion, Individualität sowie Präzision vereinen.'],
      cta: 'Mehr erfahren',
      sections: ['Beraten', 'Beschaffen', 'Betreiben'],
    },
    management: {
      title: 'Exklusives Aircraft Management im Segment ‘Business Aviation’ neu definiert',
      text: 'Unter der Prämisse maximaler Diskretion entwickeln und strukturieren wir maßgeschneiderte Betreibermodelle für Flugzeugeigentümer, begleiten diese mit bedingungsloser Professionalität und setzen die individuellen Ansprüche mit maximaler Leidenschaft, Gewissenhaftigkeit und Transparenz im täglichen Flugbetrieb um.',
      sections: [
        {
          title: 'Loyalität',
        },
        {
          title: 'Exklusivität',
        },
        {
          title: 'Innovation',
        },
      ],
    },
    specials: {
      headline: 'Wertschöpfung',
      subline: 'Zeit ist Ihr wertvollstes Gut.',
      text: 'Ihre Termine müssen und sollen perfekt abgestimmt sein. Daher legen wir größtes Augenmerk auf präzise Abläufe, Transparenz und Diskretion. So können Sie sich jederzeit auf eine nahtlose Organisation mit dem höchsten Maß an Sicherheit und Komfort verlassen.',
      bullets: {
        title: 'Spezialisierungen',
        items: ['fachliche Expertise', 'reibungslose Organisation', 'Diskretion und Sicherheit'],
      },
    },
    numbers: {
      items: ['Flüge', 'Mio Meilen', 'Airports'],
    },
    ansprueche: {
      title: 'Lösungen für höchste Ansprüche',
      items: [
        {
          title: 'Flexibel & Spontan',
          text: '... für Nutzer, die in ihrem Tagesgeschäft höchste Flexibilität, Unabhängigkeit und optimales Zeitmanagement benötigen – mit individuellen Abflugzeiten, spontanen Terminierungen und flexiblen Routings.',
        },
        {
          title: 'Privatsphäre',
          text: '... für Nutzer, die höchsten Wert auf Diskretion legen und für die erhöhte Sicherheitsstandards unverzichtbar sind - sowohl beruflich als auch privat',
        },
        {
          title: 'Remote Zielorte',
          text: '... für Nutzer die auch Zielorte erreichen müssen / wollen, die weit ab von großen und internationalen Airports (Hubs) lokalisiert sind',
        },
      ],
    },
    contactCTASection: {
      subline: 'Your Sky Your Way',
      title: ['Ihr Geschäftsreiseflugzeug.', 'Ihre Entscheidung.'],
      text: ['Das Team von FIRST CLASS JETS kümmert sich um ALLE Details.', 'Nehmen Sie jederzeit gern mit uns Kontakt auf!'],
      cta: 'Jetzt Kontaktieren',
    },
  },

  service: {
    headline: '360 Grad Service',
    subline: 'Aircraft Management aus einer Hand.',
    text: 'FIRST CLASS JETS bietet ganzheitliche Services - individuell, transparent, diskret sowie professionell und präzise aufeinander abgestimmt:',
    cta: 'Jetzt Kontaktieren',
    sections: [
      {
        title: 'Beraten',
        text: 'Von der Bedarfsermittlung über Betreibermodelle bis hin zu Finanzierungsoptionen stehen wir mit klaren Analysen zur Seite.',
      },
      {
        title: 'Beschaffen',
        text: 'Ganz gleich, ob Sie ein Flugzeug neu oder gebraucht erwerben möchten, technisch überprüfen lassen oder registrieren, ein- und/oder überführen wollen oder Ihr bisheriges Aircraft Management wechseln möchten - wir übernehmen jeden Schritt sowohl individuell als auch professionell und präzise und sorgen somit für eine reibungslose Abwicklung.',
      },
      {
        title: 'Betreiben',
        text: 'Ihren individuellen Flugbetrieb organisieren und gewährleisten wir rund um die Uhr - 24/7 - und weltweit, planen die Wartungsintervalle sowie die Crews und führen das Controlling durch und setzen sämtliche administrativ notwendigen Aufgaben um - damit Ihr Flugzeug IMMER uneingeschränkt einsatzbereit ist.',
      },
    ],
  },

  about: {
    intro: {
      text: [
        'ist Ihr Partner in sämtlichen Bereichen des ‚Aircraft Managements‘ in der ‚Business Aviation‘.',
        'Ihre Zeit verstehen wir als wertvollstes Gut.',
        'Dementsprechend gestalten wir für Sie das ‚Aircraft Management‘ sowohl maximal individuell als auch transparent und eﬃzient, stets darauf ausgerichtet, nie den Faktor Sicherheit zu vernachlässigen.',
      ],
      cta: 'Mehr erfahren',
    },
    history: {
      headline: 'Geschichte und Philosophie',
      subline: 'Seit über zwei Jahrzehnten gestalten und pflegen wir unsere Vision der ‚Business Aviation‘.',
      text: [
        'Gegründet in Berlin, haben wir es uns zur Aufgabe gemacht, ‚Aircraft Management‘ für jeden Flugzeugeigentümer einzigartig und exzellent erleben zu können. Der Weg führte uns von der Vision hin zu einer weltweit geschätzten Marke, die Individualität, Exklusivität sowie Professionalität und Leidenschaft verbindet.',
        'Dank unserer langjährigen Erfahrung und einem starken Netzwerk begleiten wir Eigentümer in jeder Phase - von der Auswahl des idealen Geschäftsreiseflugzeugs über die maßgeschneiderte Betriebsstruktur bis hin zur vollständigen Verwaltung. Mit höchster Diskretion, präziser Planung und kompromissloser Qualität setzen wir neue Standards in der Business Aviation.',
      ],
    },
    ingo: {
      headline: ['Ingo Kersten - ', 'mehr als (nur) Pilot'],
      text: [
        'Ingo Kersten, Gründer und CEO von FIRST CLASS JETS, ist nicht nur lizensierter Verkehrspilot (ATPL) mit mehreren tausend Flugstunden in den Cockpits diverser Geschäftsreiseflugzeuge sondern auch individueller Ansprechpartner in allen Fragen des ‚Aircraft Managements‘.',
        'Mit seiner langjährigen und facettenreichen Expertise unterstützt er höchst professionell u.a. bei der Wahl des passenden Flugzeugs, beim Erstellen von Betreibermodellen sowie auch bei der Optimierung bestehender Prozesse.',
        'Seine Kompetenz macht nicht nur den Unterschied hinsichtlich wichtiger Entscheidungsfindungen am Boden sondern unterstreicht auch die maximal hohen Ansprüche in jedem Segment der Flugdurchführung.',
      ],
    },
    werte: {
      headline: 'Unsere Werte',
      items: [
        {
          title: 'Professionalität',
          text: 'mehr als 25 Jahre Erfahrung, Sicherheitsbewusstsein, zukunftsorientiert',
        },
        {
          title: 'Exklusivität',
          text: 'jede Entscheidung wird individuell auf Ihre Wünsche abgestimmt',
        },
        {
          title: 'Vertrauen',
          text: 'durch Diskretion, Zuverlässigkeit und Transparenz',
        },
      ],
    },
  },

  contact: {
    headline: 'Kontakt',
    text: 'Lassen Sie uns wissen, wie wir Ihnen unsere Kompetenzen unter Beweis stellen dürfen. Schreiben Sie uns oder rufen Sie uns an - unkompliziert und dennoch diskret.',
    cta: 'Jetzt senden',
    form: {
      title: 'Sende uns eine Nachricht',
      success: 'Ihre Nachricht wurde erfolgreich verschickt.',
      error: 'Beim Senden Ihrer Nachricht ist ein Fehler aufgetreten.',
    },
  },

  footer: {
    contact: 'Kontakt',
    links: 'Links',
    followUs: 'Folge uns auf',
    legalLinks: [
      { name: 'Impressum', link: '/impressum' },
      { name: 'Datenschutz', link: '/datenschutz' },
    ],
    socialLinks: [
      { icon: 'linkedin', link: 'https://www.linkedin.com/company/1stclassjets' },
      { icon: 'instagram', link: 'https://www.instagram.com/1stclassjets/' },
      { icon: 'facebook', link: 'https://www.facebook.com/1stClassJets' },
    ],
  },

  generic: {
    level: {
      title: 'Rolle',
      ADMIN: 'Administrator',
      MEMBER: 'Mitarbeiter',
    },
    company: {
      brandName: 'First Class jets',
      name: '1stClassJets IKR Aviation Services GmbH',
      street: 'Kurfürstendamm 14',
      city: '10719 Berlin (Germany)',
      phone: '+49 (0) 30 992 113 394',
      mail: 'mail@1stclassjets.de',
    },
    message: 'Message',
    settings: 'Einstellungen',
    hourSuffix: ' Stunden',
    squareMeterSuffix: ' m²',
    timestampPrefix: 'Erstellt am: ',
    phonePrefix: 'Telefon: ',
    mailPrefix: 'E-Mail: ',
    websitePrefix: 'Webseite: ',
    employeeSuffix: ' Mitarbeiter',
    salutation: 'Anrede',
    name: 'Name',
    firstname: 'Vorname',
    lastname: 'Nachname',
    birthday: 'Geburtsdatum',
    personType: 'Art',
    personTypePlaceholder: 'Beirat, Geschäftsführer, ...',
    street: 'Straße, Hausnummer',
    companyName: 'Firmenname',
    club: 'Verein (optional)',
    city: 'PLZ, Ort',
    mail: 'E-Mail',
    identifier: 'E-Mail',
    phone: 'Telefon',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    createdOn: 'Erstellt am',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    close: 'Schließen',
    send: 'Senden',
    error: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.',
    acceptAGB: 'Ich akzeptiere die AGB',
    percent: ' %',
    kilometerSuffix: ' km',
    upload: 'Hochladen',
    login: 'Anmelden',
    logout: 'Abmelden',
    export: 'Export',
    import: 'Import',
    save: 'Speichern',
  },

  UI: {
    csvImport: {
      title: 'Import',
      startCTA: 'Import starten',
      loading: 'Daten werden imporiert...',
      error: 'Der Import ist fehlgeschlagen.',
    },
    dropdown: {
      emptySelection: 'Bitte wählen',
      search: 'Suche',
    },
    selectableTagList: {
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger',
    },
    textarea: {
      max: {
        prefix: 'maximal ',
        suffix: ' Zeichen',
      },
    },
    dnd: {
      text: 'Bewerbung anhängen',
      hint: '(Erlaubt ist eine PDF-Datei bis zu 25 MB)',
      selectedPrefix: 'Gewählte PDF: ',
      error: {
        size: 'Maximale Dateigröße überschritten',
      },
    },
  },

  resetPassword: {
    passwordsDontMatch: 'Passwörter stimmen nicht überein',
    passwordReset: 'Passwort wurde erfolgreich geändert',
    save: 'Ändern',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    title: 'Passwort ändern',
  },
}

export const langKey = 'DE'
