import cursorIcon from '../../../assets/image/cursor.svg'

const Cursor: React.FC = () => {
  return (
    <div className="md:hidden absolute bottom-0 left-0 w-full pointer-events-none">
      <div className="flex flex-col items-center pb-5 gap-10">
        <img src={cursorIcon} alt="cursor" className="w-6 md:w-8" />
      </div>
    </div>
  )
}

export default Cursor
