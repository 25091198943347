import hero from '../../../assets/image/home/hero/hero.webp'
import text from '../../../assets/image/home/hero/text.webp'
import textMobile from '../../../assets/image/home/hero/text_mobile.webp'
import icon from '../../../assets/image/logo/icon.svg'
import Cursor from '../../UI/Cursor/Cursor'
import Header from '../../UI/Layout/Header/Header'

const Hero = () => {
  return (
    <div className="h-screen w-full bg-cover bg-center relative" style={{ backgroundImage: `url(${hero})` }}>
      <Header bottom />
      <div className="flex h-full w-full items-center justify-center flex-col gap-5 md:gap-10">
        <img src={icon} alt="1stClassJets" className="w-20 md:w-32" />
        <img src={text} alt="1stClassJets" className="hidden md:block w-2/3 mb-20" />
        <img src={textMobile} alt="1stClassJets" className="md:hidden w-[80%] max-w-[400px] mb-20" />
      </div>
      <Cursor />
    </div>
  )
}

export default Hero
