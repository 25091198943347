import image from '../../../assets/image/about/intro/image.webp'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Container from '../../UI/Container/Container'
import Button from '../../UI/Button/Button'
import logoNoIcon from '../../../assets/image/logo/logo_no_icon.svg'

const Intro = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <div className="pt-14 md:pt-32">
      <Container>
        <div className="flex flex-col md:flex-row gap-14 md:gap-28 md:pl-20 md:items-center">
          <div className="md:w-3/5 flex flex-col gap-7">
            <div>
              <img src={logoNoIcon} alt="FIRST CLASS JETS" className="h-5 md:h-6" />
            </div>
            <div className="flex flex-col gap-5">
              {t.about.intro.text.map((text, i) => (
                <div className="font-thin text-white" key={i}>
                  {text}
                </div>
              ))}
            </div>
            <Button small text={t.about.intro.cta} href="/service" contrast />
          </div>
          <div className="md:w-2/5 shrink-0 bg-cover bg-center aspect-square" style={{ backgroundImage: `url(${image})` }}></div>
        </div>
      </Container>
    </div>
  )
}

export default Intro
