import Hero from '../UI/Hero/Hero'
import Layout from '../UI/Layout/Layout'
import bg from '../../assets/image/contact/hero/bg.webp'
import text from '../../assets/image/contact/hero/text.svg'
import FormWrapper from './FormWrapper/FormWrapper'

const Contact = () => {
  return (
    <Layout>
      <Hero bg={bg}>
        <img src={text} alt="Contact" className="h-16" />
      </Hero>
      <FormWrapper />
    </Layout>
  )
}

export default Contact
