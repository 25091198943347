import { useNavigate } from 'react-router-dom'
import Navigation from './Navigation/Navigation'
import logo from '../../../../assets/image/logo/logo.svg'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import Button from '../../Button/Button'

type Props = { hideNavigation?: boolean; small?: boolean; bottom?: boolean }

const Header: React.FC<Props> = ({ hideNavigation = false, small = false, bottom = false }) => {
  const navigate = useNavigate()
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const isLoggedIn = () => !!accountLink
  const t = useSelector((s) => s[props.TRANSLATION])

  const goHome = () => {
    let path = '/'
    if (isLoggedIn()) {
      path = accountLink.level === 'ADMIN' ? '/admin' : '/employee'
    }
    navigate(path)
  }

  return (
    <div
      className={`z-20 h-20 md:h-24 transition-all left-0 absolute ${
        bottom ? 'top-0 md:top-auto md:bottom-0' : 'top-0'
      } w-full flex flex-row items-center justify-between px-5 md:px-10`}
    >
      <div className="md:w-3/12">
        <div onClick={goHome} className="cursor-pointer">
          <img src={logo} alt={t.projectName} className="h-7" />
        </div>
      </div>
      <div className="md:w-6/12 flex justify-center">{!hideNavigation && <Navigation />}</div>
      <div className="hidden md:flex w-3/12 justify-end">
        <Button text="Kontakt" href="/contact" invert />
      </div>
    </div>
  )
}

export default Header
