import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'
import props from '../../../redux/props'
import section1 from '../../../assets/image/service/sections/1.webp'
import section2 from '../../../assets/image/service/sections/2.webp'
import section3 from '../../../assets/image/service/sections/3.webp'
import Button from '../../UI/Button/Button'
import ContactCTASection from '../../Home/ContactCTASection/ContactCTASection'
import { useEffect } from 'react'

const Sections = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const images = [section1, section2, section3]

  useEffect(() => {
    //  move to section if # is in url
    if (window.location.hash) {
      const id = window.location.hash.replace('#', '')
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [])

  return (
    <div>
      <Container className="py-20 md:pb-0 md:pt-32 relative">
        <div className="font-bold text-xl text-white text-center">{t.service.headline}</div>
        <div className="font-bold text-white text-center">{t.service.subline}</div>
        <div className="font-thin text-white text-center md:w-2/3 text-sm mx-auto mt-7">{t.service.text}</div>
        <div className="flex flex-col mt-14 gap-20 md:gap-0">
          {t.service.sections.map((item, i) => (
            <div key={i} id={item.title} className={`flex flex-col gap-10 md:gap-0 ${i % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'}`}>
              <div className="md:w-1/2 flex items-center md:px-20">
                <div>
                  <div className="text-2xl text-white font-bold">{item.title}</div>
                  <div className="text-white my-5">{item.text}</div>
                  <Button text={t.service.cta} href="/contact" invert />
                </div>
              </div>
              <div className="md:w-1/2 aspect-square">
                <img src={images[i]} alt={item.title} className="w-full h-full object-cover" />
              </div>
            </div>
          ))}
        </div>
      </Container>
      <ContactCTASection />
    </div>
  )
}

export default Sections
