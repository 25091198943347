import Header from '../../UI/Layout/Header/Header'

type Props = {
  bg: string
  children: React.ReactNode
}

const Hero: React.FC<Props> = ({ bg, children }) => {
  return (
    <div className="h-[80vh] w-full bg-cover bg-center relative" style={{ backgroundImage: `url(${bg})` }}>
      <Header />
      <div className="flex h-full w-full items-center justify-center flex-col gap-10 px-10">{children}</div>
    </div>
  )
}

export default Hero
