import axios from 'axios'
import { awsLambdaURLSendMail } from '../../../utility'

type Data = {
  name: string
  mail: string
  message: string
}

export const submitToLambda = async (data: Data) => {
  try {
    const result = await axios({
      method: 'POST',
      url: awsLambdaURLSendMail,
      data,
    })
    return result.data
  } catch (err) {
    return false
  }
}

export default submitToLambda
