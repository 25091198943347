import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'
import props from '../../../redux/props'
import bg from '../../../assets/image/home/contactCTASection/bg.webp'
import bgMobile from '../../../assets/image/home/contactCTASection/bg_mobile.webp'
import arrow from '../../../assets/image/home/contactCTASection/arrow.svg'
import Button from '../../UI/Button/Button'
import styled from 'styled-components'

const ContactCTASection = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const Background = styled.div`
    background-image: url(${bg});
    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      background-image: url(${bgMobile});
    }
  `

  return (
    <Background className="py-32 bg-cover bg-left md:bg-center">
      <Container>
        <div className="flex flex-col md:flex-row gap-14 md:gap-20">
          <div className="shrink-0 flex-1">
            <div className="text-goldLight uppercase font-thin">{t.home.contactCTASection.subline}</div>
            <div>
              {t.home.contactCTASection.title.map((text, i) => (
                <div className="text-white font-bold text-lg md:text-xl whitespace-nowrap" key={i}>
                  {text}
                </div>
              ))}
            </div>
            <div className="mt-3">
              {t.home.contactCTASection.text.map((text, i) => (
                <div className="text-white text-sm" key={i}>
                  {text}
                </div>
              ))}
            </div>
          </div>
          <div className="flex-1 flex items-center justify-center">
            <img src={arrow} alt="arrow" className="w-14 md:w-28 rotate-90 md:rotate-0" />
          </div>
          <div className="flex-1 flex items-center justify-center">
            <Button text={t.home.contactCTASection.cta} href="/contact" contrast />
          </div>
        </div>
      </Container>
    </Background>
  )
}

export default ContactCTASection
