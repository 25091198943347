import Layout from '../UI/Layout/Layout'
import Container from '../UI/Container/Container'
import './legal.scss'
import Header from '../UI/Layout/Header/Header'

const Imprint = () => {
  return (
    <Layout>
      <Header />
      <Container>
        <div className="legal">
          <h1>Impressum</h1>

          <p>
            1stClassJets IKR Aviation Services GmbH
            <br />
            Kurf&uuml;rstendamm 14
            <br />
            10719 Berlin
          </p>

          <p>
            Handelsregister: HRB 214878 B<br />
            Registergericht: Amtsgericht Berlin-Charlottenburg
          </p>

          <p>
            <strong>Vertreten durch:</strong>
            <br />
            Ingo Kersten
          </p>

          <h2>Kontakt</h2>
          <p>
            Telefon: +49 (0) 30 992 113 394
            <br />
            E-Mail: mail@1stclassjets.de
          </p>

          <h2>Umsatzsteuer-ID</h2>
          <p>
            Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:
            <br />
            DE 333 73 21 70
          </p>

          <h2>Redaktionell verantwortlich</h2>
          <p>Ingo Kersten</p>

          <h2>EU-Streitschlichtung</h2>
          <p>
            Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:{' '}
            <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">
              https://ec.europa.eu/consumers/odr/
            </a>
            .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>

          <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
          <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        </div>
      </Container>
    </Layout>
  )
}

export default Imprint
