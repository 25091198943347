import styled from 'styled-components'

const StyledShadow = styled.div`
  background: rgb(6, 11, 40);
  background: linear-gradient(180deg, rgba(6, 11, 40, 1) 0%, rgba(1, 1, 2, 0) 100%);
`

const Shadow: React.FC<{ className?: string; bottom?: boolean }> = ({ className, bottom }) => {
  return <StyledShadow className={`h-20 z-[1] absolute w-full bg-black ${bottom ? 'bottom-0 rotate-180' : 'top-0'} ${className}`}></StyledShadow>
}

export default Shadow
