import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Container from '../../UI/Container/Container'

const Werte = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="py-14 md:py-32">
      <Container>
        <div className="text-xl font-bold text-white">{t.about.werte.headline}</div>
        <div className="flex flex-col md:flex-row gap-10 md:gap-14 md:pl-20 mt-10">
          {t.about.werte.items.map((wert, i) => (
            <div key={i} className="flex-1">
              <div className="font-thin text-white uppercase tracking-wider">{wert.title}</div>
              <div className="text-white font-thin mt-1">{wert.text}</div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Werte
