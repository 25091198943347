import Hero from '../UI/Hero/Hero'
import Layout from '../UI/Layout/Layout'
import bg from '../../assets/image/about/hero/bg.webp'
import text from '../../assets/image/about/hero/text.svg'
import Intro from './Intro/Intro'
import History from './History/History'
import Ingo from './Ingo/Ingo'
import Werte from './Werte/Werte'
import ContactCTASection from '../Home/ContactCTASection/ContactCTASection'

const About = () => {
  return (
    <Layout>
      <Hero bg={bg}>
        <img src={text} alt="1stClassJets" className="h-16" />
      </Hero>
      <Intro />
      <History />
      <Ingo />
      <Werte />
      <ContactCTASection />
    </Layout>
  )
}

export default About
