import image from '../../../assets/image/about/ingo/image.webp'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Container from '../../UI/Container/Container'

const Ingo = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <div className="pt-20 md:pt-32">
      <Container>
        <div className="flex flex-col md:flex-row gap-14 md:gap-20 items-center">
          <div className="w-full md:w-2/5 bg-cover bg-center aspect-square" style={{ backgroundImage: `url(${image})` }}></div>
          <div className="md:w-3/5 flex flex-col gap-7">
            <div>
              {t.about.ingo.headline.map((headline, i) => (
                <div key={i} className="font-bold text-xl text-white leading-8">
                  {headline}
                </div>
              ))}
            </div>
            <div className="flex flex-col gap-5">
              {t.about.ingo.text.map((text, i) => (
                <div className="font-thin text-white" key={i}>
                  {text}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Ingo
