import bg from '../../../assets/image/home/intro/bg.webp'
import Shadow from '../../UI/Shadow/Shadow'
import beraten from '../../../assets/image/home/intro/beraten.webp'
import beschaffen from '../../../assets/image/home/intro/beschaffen.webp'
import betreiben from '../../../assets/image/home/intro/betreiben.webp'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Container from '../../UI/Container/Container'
import Button from '../../UI/Button/Button'
import styled from 'styled-components'
import logoNoIcon from '../../../assets/image/logo/logo_no_icon.svg'

const Intro = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const images = [beraten, beschaffen, betreiben]

  const Background = styled.div`
    background: rgb(0, 33, 67);
    background: linear-gradient(180deg, rgba(0, 33, 67, 1) 0%, rgba(0, 33, 67, 0.9) 75%, rgba(0, 33, 67, 0.75) 100%);
  `

  return (
    <div className="py-40 bg-blue relative bg-repeat bg-cover" style={{ backgroundImage: `url(${bg})` }}>
      <Shadow />
      <Background className="absolute w-full h-full left-0 top-0 z-[0]" />
      <div className="relative z-10">
        <Container>
          <div className="flex flex-col items-center">
            <div className="text-gold uppercase">{t.home.intro.welcome}</div>
            <div className="my-5">
              <img src={logoNoIcon} alt="FIRST CLASS JETS" className="h-5 md:h-6" />
            </div>
            <div className="pb-5">
              {t.home.intro.text.map((t, i) => (
                <div className="text-sm text-white text-center" key={i}>
                  {t}
                </div>
              ))}
            </div>
            <Button href="/service" text={t.home.intro.cta} contrast />
          </div>
          <div className="flex flex-col md:flex-row gap-5 md:px-20 mt-32">
            {t.home.intro.sections.map((s, i) => (
              <a
                href={`/service#${s}`}
                key={i}
                className="w-full aspect-square md:h-full md:aspect-[3/6] bg-cover bg-center flex-1"
                style={{ backgroundImage: `url(${images[i]})` }}
              >
                <div className="text-white text-xl font-bold p-5">{s}</div>
              </a>
            ))}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Intro
